import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const styles = {
  unclickableImage: {
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none'
  }
};

const HintPageTemplate = ({ hints }) => {
  const [currentHint, setCurrentHint] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [utmParams, setUtmParams] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmString = [
      `utm_source=${params.get('utm_source') || 'blackwoodmanorbox'}`,
      `utm_medium=${params.get('utm_medium') || 'qr'}`,
      `utm_campaign=${params.get('utm_campaign') || 'hints'}`,
      `campaign_id=${location.pathname.split('/').pop()}`
    ].join('&');

    setUtmParams(utmString);

    // Add UTM params if they're not present
    if (!window.location.search) {
      navigate(`${location.pathname}?${utmString}`, { replace: true });
    }
  }, [location.pathname]);

  const previousHint = () => {
    setCurrentHint((prev) => (prev - 1 + hints.length) % hints.length);
  };

  const nextHint = () => {
    setCurrentHint((prev) => (prev + 1) % hints.length);
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center font-serif p-8">
      <div className="max-w-2xl w-full space-y-8">
        <h2 className="text-3xl text-orange-300 text-center mb-8">
          {hints[currentHint].title}
        </h2>
        <div className="flex justify-center">
          <div style={styles.unclickableImage}>
            <img
              src={hints[currentHint].image}
              alt="Hint"
              className="max-w-full h-auto mb-8"
              onContextMenu={(e) => e.preventDefault()}
              draggable="false"
            />
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <button
            onClick={previousHint}
            disabled={currentHint === 0}
            className="px-6 py-3 bg-purple-900 hover:bg-purple-800 text-gray-200 rounded-sm disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={nextHint}
            disabled={currentHint === hints.length - 1}
            className="px-6 py-3 bg-purple-900 hover:bg-purple-800 text-gray-200 rounded-sm disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const hintSets = {
  formula: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  wallcipher: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  venndiagram: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  ukmap: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  safe: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  laptop: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  fingerprint: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  shoesize: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
],
  door: [
  {
    title: 'Hint 1/7',
    image: '/images/hints/Box1/formulahint1.png',
  },
  {
    title: 'Hint 2/7',
    image: '/images/hints/Box1/formulahint2.png',
  },
  {
    title: 'Hint 3/7',
    image: '/images/hints/Box1/formulahint3.png',
  },
  {
    title: 'Hint 4/7',
    image: '/images/hints/Box1/formulahint4.png',
  },
  {
    title: 'Hint 5/7',
    image: '/images/hints/Box1/formulahint5.png',
  },
  {
    title: 'Hint 6/7',
    image: '/images/hints/Box1/formulahint6.png',
  },
  {
    title: 'Hint 7/7',
    image: '/images/hints/Box1/formulahint7.png',
  }
]
};

const HintPage = () => {
  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const hints = hintSets[path] || [];

  return <HintPageTemplate hints={hints} />;
};

export default HintPage;