import React from 'react'
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom'
import ParaboxLanding from './components/ParaboxLanding'
import FingerprintKeyPage from './components/FingerprintKeyPage'
import HintPage from './components/Box1Hints'

function App() {
  const params = "utm_source=blackwoodmanorbox&utm_medium=qr&utm_campaign=hints"

  return (
    <HashRouter>
      <div className="min-h-screen bg-black">
        <Routes>
          <Route index element={<ParaboxLanding />} />
          <Route path="fingerprint-key" element={<FingerprintKeyPage />} />
          <Route path="formula" element={<HintPage />} />
          <Route path="wallcipher" element={<HintPage />} />
          <Route path="venndiagram" element={<HintPage />} />
          <Route path="ukmap" element={<HintPage />} />
          <Route path="safe" element={<HintPage />} />
          <Route path="laptop" element={<HintPage />} />
          <Route path="fingerprint" element={<HintPage />} />
          <Route path="shoesize" element={<HintPage />} />
          <Route path="door" element={<HintPage />} />

          {/* Short URL redirects */}
          <Route path="vm8kyt3h" element={<Navigate to={`/formula?${params}&campaign_id=formula`} replace />} />
          <Route path="nr9pzx2m" element={<Navigate to={`/wallcipher?${params}&campaign_id=wallcipher`} replace />} />
          <Route path="jw5qth8f" element={<Navigate to={`/venndiagram?${params}&campaign_id=venndiagram`} replace />} />
          <Route path="kp4mxw7c" element={<Navigate to={`/ukmap?${params}&campaign_id=ukmap`} replace />} />
          <Route path="hs2vbn9d" element={<Navigate to={`/safe?${params}&campaign_id=safe`} replace />} />
          <Route path="tq6lrj5g" element={<Navigate to={`/laptop?${params}&campaign_id=laptop`} replace />} />
          <Route path="zx3nwq8v" element={<Navigate to={`/fingerprint?${params}&campaign_id=fingerprint`} replace />} />
          <Route path="yd1pkm5h" element={<Navigate to={`/shoesize?${params}&campaign_id=shoesize`} replace />} />
          <Route path="bc7ftr2j" element={<Navigate to={`/door?${params}&campaign_id=door`} replace />} />
        </Routes>
      </div>
    </HashRouter>
  )
}

export default App